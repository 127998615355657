var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-width": "150px" },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "外部id：" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入消费者外部id" },
                        model: {
                          value: _vm.form.externalId,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form,
                              "externalId",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "form.externalId",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "昵称：" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入消费者昵称" },
                        model: {
                          value: _vm.form.nickname,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form,
                              "nickname",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "form.nickname",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "手机号：", prop: "phone" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入消费者手机号" },
                        model: {
                          value: _vm.form.phone,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form,
                              "phone",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "form.phone",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "添加黑名单原因：" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          placeholder: "请输入加入黑名单原因",
                        },
                        model: {
                          value: _vm.form.blacklistReason,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form,
                              "blacklistReason",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "form.blacklistReason",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-button",
                {
                  staticClass: "ml0",
                  attrs: { type: "text" },
                  on: { click: _vm.onSubmit },
                },
                [
                  _c("i", { staticClass: "el-icon-circle-plus iconsize" }),
                  _c("span", { staticClass: "iconsize" }, [_vm._v("添加")]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          attrs: {
            data: _vm.tableData,
            border: "",
            "header-cell-style": { background: "#E8E8E8", color: "#606266" },
          },
        },
        [
          _c("el-table-column", {
            attrs: { type: "index", label: "序号", width: "50px" },
          }),
          _c("el-table-column", {
            attrs: { prop: "externalId", label: "消费者外部id" },
          }),
          _c("el-table-column", { attrs: { prop: "phone", label: "手机号" } }),
          _c("el-table-column", { attrs: { prop: "nickname", label: "昵称" } }),
          _c("el-table-column", {
            attrs: { prop: "blacklistReason", label: "加入黑名单原因" },
          }),
          _c("el-table-column", {
            attrs: { width: "100px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "button",
                      {
                        staticClass: "iconColor",
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return function () {
                              return _vm.del(scope.$index, _vm.tableData)
                            }.apply(null, arguments)
                          },
                        },
                      },
                      [_c("i", { staticClass: "el-icon-delete iconColor" })]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "btnstyle" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.submit } },
            [_vm._v("提交")]
          ),
          _c("el-button", { attrs: { plain: "" }, on: { click: _vm.close } }, [
            _vm._v("关闭"),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
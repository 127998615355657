<!--
 * @Author: 冯杰
 * @Date: 2021-07-28 11:03:47
 * @LastEditTime: 2021-08-04 16:39:18
 * @LastEditors: OBKoro1
 * @Description: 新增黑名单页面
-->
<template>
  <div>
    <el-form ref="form" :model="form" :rules="rules" label-width="150px">
      <el-row>
        <el-col :span="8" >
          <el-form-item label="外部id：">
            <el-input v-model.trim="form.externalId" placeholder="请输入消费者外部id" />
          </el-form-item>
        </el-col>
        <el-col :span="8" >
          <el-form-item label="昵称：">
            <el-input v-model.trim="form.nickname" placeholder="请输入消费者昵称" />
          </el-form-item>
        </el-col>
        <el-col :span="8" >
          <el-form-item label="手机号：" prop="phone">
            <el-input v-model.trim="form.phone" placeholder="请输入消费者手机号" />
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item label="添加黑名单原因：">
            <el-input v-model.trim="form.blacklistReason" type="textarea" placeholder="请输入加入黑名单原因" />
          </el-form-item>
        </el-col>
        <!-- <el-col> -->
          <!-- <el-form-item > -->
            <el-button type="text"  @click="onSubmit" class="ml0">
              <i class="el-icon-circle-plus iconsize" /><span class="iconsize">添加</span>
            </el-button>
          <!-- </el-form-item> -->
        <!-- </el-col> -->
      </el-row>
    </el-form>
    <el-table :data="tableData" border :header-cell-style="{background:'#E8E8E8',color:'#606266'}">
      <el-table-column type="index" label="序号" width="50px" />
      <el-table-column prop="externalId" label="消费者外部id" />
      <el-table-column prop="phone" label="手机号" />
      <el-table-column prop="nickname" label="昵称" />
      <el-table-column prop="blacklistReason" label="加入黑名单原因" />
      <el-table-column width="100px">
        <template v-slot="scope">
          <button type="text" class="iconColor" @click.prevent="() => del(scope.$index,tableData)"><i class="el-icon-delete iconColor" /></button>
        </template>
      </el-table-column>
    </el-table>
    <el-row class="btnstyle">
      <el-button type="primary" @click="submit">提交</el-button>
      <el-button plain @click="close">关闭</el-button>
    </el-row>
  </div>
</template>

<script>
import request from '@/found/utils/request';

const phoneRules = (rule, value, callback) => {
  if (/^1[34578]\d{9}$/.test(value) === false) {
    callback(new Error('请输入正确的手机号'));
  } else {
    callback();
  }
};

export default {
  data() {
    return {
      form: {
        externalId: '',
        nickname: '',
        phone: '',
        blacklistReason: '',
      },
      rules: {
        phone: [
          { required: true, message: '请输入消费者手机号', trigger: 'blur' },
          { required: true, validator: phoneRules, trigger: 'blur' },
        ],
      },
      tableData: JSON.parse(sessionStorage.getItem('tableData')) || [],
      // resData: [],
    };
  },
  methods: {
    onSubmit() {
      // 单独验证手机号，验证成功给外部id与昵称设置值
      // this.$refs.form.validateField('phone', async (error) => {
      //   if (!error) {
      //     await request.get('/cps/v1/consumer/consumer/findByConditions', {
      //       blacklistStatus: '009',
      //     }).then((res) => {
      //       if (!res.success) return;
      //       const data = res.result.records;
      //       const rowData = data.find((item) => item.phone === this.form.phone);
      //       this.form.externalId = rowData.externalId;
      //       this.form.nickname = rowData.nickname;
      //     });
      //   }
      // });
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.form.externalId === '' || this.form.nickname === '' || this.form.phone === '' || this.form.blacklistReason === '') {
            this.$message('请填写完整信息！');
          } else {
            this.tableData.push(this.form);
            const tableDataList = JSON.stringify(this.tableData);
            sessionStorage.setItem('tableData', tableDataList);
            this.form = {
              externalId: '',
              nickname: '',
              phone: '',
              blacklistReason: '',
            };
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    del(index, rows) {
      rows.splice(index, 1);
      sessionStorage.setItem('tableData', JSON.stringify(rows));
    },
    submit() {
      const formData = this.tableData;
      console.log(formData);
      if (formData) {
        const url = '/cps/v1/consumer/consumer';
        const params = { ...formData };
        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
      this.close();
    },
    close() {
      this.$emit('onClose');
    },
  },
};
</script>

<style lang="less" scoped>
.iconsize{
  font-size: 18px;
  color: #AAAAAA;
}

.iconColor{
  border: 0;
  background-color: #fff;
  color: red;
  font-size: 18px;
}

.btnstyle{
  margin-top: 20px;
  text-align: right;
  .el-button{
    margin: 0 10px;
  }
}
.ml0{
  margin-left: 0;
  margin-bottom: 15px;
}

/deep/ .el-form-item__content{
  padding-right: 50px;
  margin-left: 0;
}
/deep/ .el-form-item__label {
  text-align: left;
}
</style>

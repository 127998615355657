/*
 * @Author: 冯杰
 * @Date: 2021-08-04 14:09:36
 * @LastEditors: OBKoro1
 * @LastEditTime: 2021-08-04 18:17:14
 * @Description: 查看详情
 */
import Form from '@/found/components/form';

export default {
  extends: Form,
  data() {
    return {
      buttons: {
        submit: false,
        close: false,
        submitAudit: false,
      },
      formFunctionCode: 'checkBlacklist',
    };
  },
  methods: {
    setRule(v) {
      const item = v;
      return item;
    },
    formComplete() {
      this.fApi.setValue({ ...this.formConfig });
    },
  },
};

/*
 * @Author: 冯杰
 * @Date: 2021-07-22 10:33:34
 * @LastEditTime: 2021-09-14 15:33:41
 * @LastEditors: Please set LastEditors
 * @Description: 黑名单列表
 */
import request from '@/found/utils/request';
import TablePage from '@/found/components/table_page';
import Modal from '@/found/components/modal';
import Add from './form/add.vue';
import Check from './form/check.js';

export default {
  name: 'blacklist',
  extends: TablePage,
  data() {
    return {
      requestUrl: '/cps/v1/web/consumerReport/findByConditions',
      requestType: 'GET',
      params: {
        blacklistStatus: '009',
      },
    };
  },
  components: {
    Modal,
    Add,
    Check,
  },
  methods: {
    modalClick({ val, row }) {
      // 点击编辑按钮传递数据到表单
      this.formConfig = {
        ...this.formConfig,
        code: val.code,
      };
      if (val.code === 'blacklist-out') {
        // 点击移出黑名单按钮
        const urls = '/cps/v1/consumer/consumer/disable';
        const text = '移出黑名单';
        this.$emit('onGetList');
        this.operationClick(urls, text);
      } else if (val.code === 'view') {
        // 查看 跳转到消费者详情页面
        this.$router.push({
          name: 'consumer',
          params: { name: 'consumer', id: row.id },
        });
      }
    },
    // 移出黑名单操作
    operationClick(urls, text) {
      let datas = [];
      if (this.selection.length > 0) {
        datas = this.selection;
        request.patch(urls, datas).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: `${text}成功`,
            });
            this.selection = [];
            this.getList();
          }
        });
      } else {
        this.$message({
          type: 'error',
          message: '请选择数据',
        });
      }
    },
    // 导入、导出
    beforeClickEvent({ val, row }) {
      if (val.code === 'export') {
        // mdm_user_import_config;
        this.exportClick({ val });
      } else if (val.code === 'import') {
        this.importClick({ val });
      } else {
        return true;
      }
    },
  },
  created() {
    // 重写获取配置信息
    this.getConfigList('blacklist');
  },
};
